import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  draggable,
  dropTargetForElements,
  monitorForElements
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import io from 'socket.io-client';
import './App.css';
import lm from "./light-mode.png";
import dm from "./dark-mode.png";
import Login from './Login';
import Signup from './Signup';

Modal.setAppElement('#root');

const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'https://timebox-api.naija.dev';

const formatDate = (date) => date.toLocaleDateString('en-GB', {
  day: '2-digit',
  month: 'long',
  year: 'numeric',
});

const App = () => {
  const [date, setDate] = useState(new Date());
  const [topPriorities, setTopPriorities] = useState([]);
  const [brainDump, setBrainDump] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newItem, setNewItem] = useState('');
  const [modalType, setModalType] = useState('');
  const [isTopPrioritiesCollapsed, setIsTopPrioritiesCollapsed] = useState(false);
  const [isBrainDumpCollapsed, setIsBrainDumpCollapsed] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [socket, setSocket] = useState(null);
  const [version, setVersion] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [view, setView] = useState('login');
  const isServerUpdate = useRef(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const theme = localStorage.getItem('theme');
    if (theme) {
      if (theme === "light") {
        document.body.classList.remove('dark-mode');
        setIsDarkMode(false);
      } else {
        document.body.classList.add('dark-mode');
        setIsDarkMode(true);
      }
    }
    if (token) {
      const newSocket = io(SERVER_HOST, {
        query: { token },
        transports: ['websocket'],
      });
      setSocket(newSocket);
      setIsAuthenticated(true);
      setView('planner');
      return () => newSocket.close();
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('update', (data) => {
        const { state, version } = data;
        isServerUpdate.current = true;
        setTopPriorities(state.topPriorities);
        setBrainDump(state.brainDump);
        setSchedule(state.schedule);
        setVersion(version);
      });

      const retryConnection = (retries = 10000) => {
        if (retries > 0) {
          setTimeout(() => {
            console.log(`Attempting to reconnect... (${10000 - retries})`);
            const token = localStorage.getItem('token');
            if (token) {
              const newSocket = io(SERVER_HOST, {
                query: { token },
                transports: ['websocket'],
              });
              setSocket(newSocket);
              newSocket.on('connect', () => {
                console.log('Reconnected successfully');
                retries = 5; // Reset retries on successful reconnection
              });
              newSocket.on('disconnect', () => {
                retryConnection(retries - 1);
              });
            }
          }, 10000);
        } else {
          console.log('Max retries reached. Logging out...');
          handleLogout();
        }
      };

      socket.on('disconnect', () => {
        console.log('Disconnected from server. Attempting to reconnect...');
        retryConnection();
      });

      socket.on('authentication_error', (data) => {
        console.log('Authentication error:', data);
        handleLogout();
      });
    }
  }, [socket]);

  const syncWithServer = (date, state) => {
    const token = localStorage.getItem('token');
    if (token) {
      if (socket) {
        socket.emit('sync', { date, state, version: version + 1, token });
        setVersion(version + 1);
      }
    } else {
      handleLogout();
    }
  };

  useEffect(() => {
    if (!isServerUpdate.current) {
      syncWithServer(formatDate(date), { topPriorities, brainDump, schedule });
    } else {
      isServerUpdate.current = false;
    }
  }, [topPriorities, brainDump, schedule]);

  const getListById = (id) => {
    switch (id) {
      case 'topPriorities':
        return topPriorities;
      case 'brainDump':
        return brainDump;
      case 'schedule':
        return schedule;
      default:
        return [];
    }
  };

  const updateListById = (id, updatedList) => {
    switch (id) {
      case 'topPriorities':
        setTopPriorities(updatedList);
        break;
      case 'brainDump':
        setBrainDump(updatedList);
        break;
      case 'schedule':
        setSchedule(updatedList);
        break;
      default:
        break;
    }
  };

  const moveItemBetweenLists = (sourceList, destinationList, sourceIndex, destIndex, newPriority, deleteSource) => {
    if (deleteSource) {
      const sourceClone = Array.from(sourceList);
      const destClone = destinationList ? Array.from(destinationList) : [];
      const [removed] = sourceClone.splice(sourceIndex, 1);
      removed.priority = newPriority;
      destClone.splice(destIndex, 0, removed);
      return { sourceList: sourceClone, destinationList: destClone };
    } else {
      const destClone = destinationList ? Array.from(destinationList) : [];
      const item = sourceList[sourceIndex];
      item.priority = newPriority;
      destClone.splice(destIndex, 0, item);
      return { sourceList: sourceList, destinationList: destClone };
    }
  };

  const moveItemFromSchedulesList = (sourceList, destinationList, sourceIndex, destIndex, newPriority) => {
    const sourceClone = Array.from(sourceList);
    const destClone = destinationList ? Array.from(destinationList) : [];
    const remv = sourceList[sourceIndex];
    remv.priority = newPriority;
    sourceClone[sourceIndex] = null;
    destClone.splice(destIndex, 0, remv);
    return { sourceList: sourceClone, destinationList: destClone };
  };

  useEffect(() => {
    return combine(
      monitorForElements({
        canMonitor({ source }) {
          return source.data && source.data.type === 'item';
        },
        onDrop(args) {
          const { location, source } = args;
          if (!location.current.dropTargets.length) return;
          const sourceList = getListById(source.data.listId);
          let target = location.current.dropTargets[0]
          // let target = location.current.dropTargets.filter((item) => item.element.className === "droppable")[0];
          const targetList = getListById(target.data.listId);
          if (!sourceList || !targetList) return;
          const indexOfSource = sourceList.findIndex((item) => item === source.data.item);
          const closestEdgeOfTarget = extractClosestEdge(target.data);
          if (target.data.listId === 'schedule' && source.data.listId !== 'schedule') {
            const scheduleIndex = parseInt(target.data.input, 10);
            if (!schedule[scheduleIndex]) {
              const result = moveItemBetweenLists(
                sourceList,
                schedule[scheduleIndex],
                indexOfSource,
                0,
                source.data.item.priority,
                false
              );
              updateListById(source.data.listId, result.sourceList);
              const newSchedule = [...schedule];
              newSchedule[scheduleIndex] = result.destinationList[0];
              setSchedule(newSchedule);
            }
          } else if (source.data.listId === 'schedule' && target.data.listId !== 'schedule') {
            return;
          } else {
            let indexOfTarget;
            const scheduleIndex = parseInt(target.data.input, 10);
            indexOfTarget = scheduleIndex;
            if (indexOfSource < 0 || (targetList.length > 0 && indexOfTarget < 0)) return;
            let newPriority = source.data.item.priority;
            if (source.data.listId === 'brainDump' && target.data.listId === 'topPriorities') newPriority = 'top';
            else if (source.data.listId === 'topPriorities' && target.data.listId === 'brainDump') newPriority = 'ordinary';
            if (sourceList === targetList) {
              if (source.data.listId === "schedule") {
                if (!indexOfTarget || (indexOfTarget === indexOfSource)) {
                  return
                }
                const sourceClone = Array.from(sourceList);
                const item = sourceClone[indexOfSource];
                sourceClone[indexOfSource] = null;
                sourceClone[indexOfTarget] = item;
                setSchedule(sourceClone);
              } else {
                // get dest index
                const indexOfTarget = targetList.findIndex((item) => item === target.data.item);
                updateListById(
                  source.data.listId,
                  reorderWithEdge({
                    list: sourceList,
                    startIndex: indexOfSource,
                    indexOfTarget,
                    closestEdgeOfTarget,
                    axis: 'vertical',
                  })
                );
              }
            } else {
              const result = moveItemBetweenLists(
                sourceList,
                targetList,
                indexOfSource,
                indexOfTarget,
                newPriority,
                true
              );
              updateListById(source.data.listId, result.sourceList);
              updateListById(target.data.listId, result.destinationList);
            }
          }
        },
      })
    );
  }, [schedule]);

  const markTaskComplete = (index) => {
    const newSchedule = [...schedule];
    const completedTask = newSchedule[index];

    if (completedTask) {
      completedTask.completed = !completedTask.completed;

      // Update all matching tasks in the schedule
      newSchedule.forEach(task => {
        if (task && task.title === completedTask.title) {
          task.completed = completedTask.completed;
        }
      });

      // Update matching tasks in topPriorities and brainDump
      const updateList = (list) => list.map(item =>
        item.title === completedTask.title ? { ...item, completed: completedTask.completed } : item
      );

      setSchedule(newSchedule);
      setTopPriorities(updateList(topPriorities));
      setBrainDump(updateList(brainDump));
    }
  };

  const fetchDataForDate = async (selectedDate) => {
    const token = localStorage.getItem('token');
    const formattedDate = formatDate(selectedDate);
    try {
      const response = await fetch(`${SERVER_HOST}/data?date=${formattedDate}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setTopPriorities(data.topPriorities);
      setBrainDump(data.brainDump);
      setSchedule(data.schedule);
    } catch (error) {
      console.error('Error fetching data for the selected date:', error);
    }
  };

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    fetchDataForDate(selectedDate);
  };

  const openModal = (type) => {
    setModalType(type);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewItem('');
  };

  const handleAddItem = () => {
    let parsedItem;
    const formattedDate = formatDate(date);
    if (modalType === 'priority') {
      parsedItem = { title: newItem, completed: false, priority: 'top', date: formattedDate };
      setTopPriorities([...topPriorities, parsedItem]);
    } else if (modalType === 'brainDump') {
      parsedItem = { title: newItem, completed: false, priority: 'ordinary', date: formattedDate };
      setBrainDump([...brainDump, parsedItem]);
    }
    closeModal();
  };

  const deleteItem = (listId, index) => {
    const list = getListById(listId);
    const newList = list.filter((_, i) => i !== index);
    updateListById(listId, newList);
  };

  const deleteScheduleItem = (index) => {
    const newSchedule = [...schedule];
    newSchedule[index] = null;
    setSchedule(newSchedule);
  };

  const initDropTarget = (el, listId, index) => {
    if (el) {
      dropTargetForElements({
        element: el,
        getData: ({ input }) => ({
          type: 'container',
          listId,
          input: index,
        }),
      });
    }
  };

  const initDraggable_old = (el, listId, item) => {
    if (el) {
      draggable({
        element: el,
        getInitialData: () => ({
          type: 'item',
          listId,
          item,
        }),
      });
      dropTargetForElements({
        element: el,
        getData: ({ input }) => ({
          type: 'item',
          listId,
          item,
          input,
        }),
        getIsSticky: () => true,
      });
    }
  };

  const initDraggable = (el, listId, item) => {
    if (el) {
      draggable({
        element: el,
        getInitialData: () => ({
          type: 'item',
          listId,
          item,
        }),
      });
      dropTargetForElements({
        element: el,
        getData: ({ input }) => ({
          type: 'item',
          listId,
          item,
          input,
        }),
        getIsSticky: () => true,
      });

      // Add click event listener for schedule items
      if (listId === 'schedule') {
        el.addEventListener('click', (event) => {
          if (event.target.classList.contains('complete-btn')) {
            const index = parseInt(el.getAttribute('data-index'), 10);
            markTaskComplete(index);
          }
        });
      }
    }
  };

  const toggleTopPriorities = () => {
    setIsTopPrioritiesCollapsed(!isTopPrioritiesCollapsed);
  };

  const toggleBrainDump = () => {
    setIsBrainDumpCollapsed(!isBrainDumpCollapsed);
  };

  const toggleDarkMode = () => {
    if (isDarkMode) {
      document.body.classList.remove('dark-mode');
    } else {
      document.body.classList.add('dark-mode');
    }
    let theme;
    if (isDarkMode) {
      theme = "light"
    } else {
      theme = "dark"
    }
    localStorage.setItem('theme', theme);
    setIsDarkMode(!isDarkMode);
  };

  // const copyEntriesToCurrentDate = () => {
  //   const currentFormattedDate = formatDate(new Date());
  //   const selectedFormattedDate = formatDate(date);
  //   const mergedTopPriorities = [...topPriorities];
  //   const mergedBrainDump = [...brainDump];
  //   setTopPriorities(mergedTopPriorities);
  //   setBrainDump(mergedBrainDump);
  //   syncWithServer({
  //     topPriorities: mergedTopPriorities,
  //     brainDump: mergedBrainDump,
  //     schedule: schedule,
  //   });
  //   openModal(null);
  //   setDate(new Date());
  // };
  const copyEntriesToCurrentDate = async () => {
    const token = localStorage.getItem('token');
    const currentFormattedDate = formatDate(new Date());
    const selectedFormattedDate = formatDate(date);

    try {
      // Fetch the state for the selected date
      const response = await fetch(`${SERVER_HOST}/data?date=${currentFormattedDate}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();

      // Copy the fetched state to the current date
      const mergedTopPriorities = [...data.topPriorities, ...topPriorities, ...schedule.filter((i) => i !== null)];
      const mergedBrainDump = [...data.brainDump, ...brainDump];
      const mergedSchedule = [...data.schedule];

      // Update the client state
      setDate(new Date());
      setTopPriorities(mergedTopPriorities);
      setBrainDump(mergedBrainDump);
      setSchedule(mergedSchedule);

      // Optionally, you can notify the user of successful copy
      openModal(null);
    } catch (error) {
      console.error('Error copying entries to current date:', error);
    }
  };

  const UserIcon = ({ color = '#e0e0e0' }) => (
    <svg width="35" height="35" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" fill={color} />
    </svg>
  );

  const handleLoginSuccess = (token) => {
    localStorage.setItem('token', token);
    const newSocket = io(SERVER_HOST, { query: { token } });
    setSocket(newSocket);
    setIsAuthenticated(true);
    setView('planner');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setView('login');
    if (socket) {
      socket.disconnect();
    }
  };

  const handleAppClick = (event) => {
    // event.preventDefault();
    if (isDropdownOpen) {
      setIsDropdownOpen(false)
    }
    // console.log("App click");
  }

  if (!isAuthenticated) {
    if (view === 'login') return <Login onLoginSuccess={handleLoginSuccess} setView={setView} setErrorMessage={setErrorMessage} isDarkMode={isDarkMode} />;
    if (view === 'signup') return <Signup setView={setView} setErrorMessage={setErrorMessage} isDarkMode={isDarkMode} />;
  }

  return (
    <div className={`App${isDarkMode ? ' dark-mode' : ''}`} onClick={handleAppClick}>
      <div className="planner">
        <div className={`header${formatDate(new Date()) !== formatDate(date) ? " nonCurrentDate" : ""}`}>
          <h1>Daily Timebox Planner</h1>
          <div className="date">
            <label>Date: </label>
            <DatePicker
              selected={date}
              onChange={handleDateChange}
              dateFormat="dd MMMM yyyy"
            />
            <span className="theme-toggle">
              {isDarkMode ? <img onClick={toggleDarkMode} src={dm} height="50" style={{ marginBottom: "", marginLeft: "10px" }} /> :
                <img onClick={toggleDarkMode} src={lm} height="50" style={{ marginBottom: "", marginLeft: "10px" }} />}
            </span>
            <div className="user-menu">
              <span onClick={() => setIsDropdownOpen(!isDropdownOpen)} style={{ cursor: "pointer" }} className="user-menu-button">
                {/* 👤 */}
                {/* &#9776;Hamburger icon */}
                <UserIcon color={isDarkMode ? '#e0e0e0' : '#ffffff'} />
              </span>
              {isDropdownOpen && (
                <div className="dropdown-menu">
                  <button onClick={() => console.log('Settings clicked')}>Settings</button>
                  <button onClick={handleLogout}>Logout</button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="top-brain-container">
          <div className="left-side">
            <div className={`top-priorities ${isTopPrioritiesCollapsed ? 'collapsed' : ''}`}>
              <h3 onClick={toggleTopPriorities}>Top Priorities {isTopPrioritiesCollapsed ? '+' : '-'}</h3>
              {!isTopPrioritiesCollapsed && (
                <>
                  <div className="droppable" id="priorities" ref={(el) => initDropTarget(el, 'topPriorities')} onDoubleClick={() => openModal('priority')}>
                    {topPriorities.map((priority, index) => (
                      <div
                        key={index}
                        ref={(el) => initDraggable(el, 'topPriorities', priority)}
                        className="draggable priority-item"
                        style={priority.completed ? { backgroundColor: '#b8ebb8' } : { backgroundColor: '#fff3cd' }}
                      >
                        {priority.title}
                        <span className="delete-btn" onClick={() => deleteItem('topPriorities', index)}>x</span>
                      </div>
                    ))}
                  </div>
                  <button onClick={() => openModal('priority')}>Add To Priority</button>
                  {formatDate(new Date()) !== formatDate(date) ? <button style={{ marginLeft: "5px" }} onClick={copyEntriesToCurrentDate}>Copy to Current Date</button> : null}
                </>
              )}
            </div>
            <div className={`brain-dump ${isBrainDumpCollapsed ? 'collapsed' : ''}`}>
              <h3 onClick={toggleBrainDump}>Brain Dump {isBrainDumpCollapsed ? '+' : '-'}</h3>
              {!isBrainDumpCollapsed && (
                <>
                  <div className="droppable" id="dumps" ref={(el) => initDropTarget(el, 'brainDump')} onDoubleClick={() => openModal('brainDump')}>
                    {brainDump.map((item, index) => (
                      <div
                        key={index}
                        ref={(el) => initDraggable(el, 'brainDump', item)}
                        className="draggable brain-dump-item"
                        style={{ backgroundColor: '#d1ecf1' }}
                      >
                        {item.title}
                        <span className="delete-btn" onClick={() => deleteItem('brainDump', index)}>x</span>
                      </div>
                    ))}
                  </div>
                  <button onClick={() => openModal('brainDump')}>Add To Brain Dump</button>
                </>
              )}
            </div>
          </div>
          <div className="right-side">
            <h3>Schedule</h3>
            <div className="droppable schedule-table">
              <div className="schedule-header"></div>
              <div className="schedule-header">00</div>
              <div className="schedule-header">30</div>
              {Array.from({ length: 19 }, (_, index) => {
                const hour = index + 5;
                return (
                  <React.Fragment key={`hour-${hour}`}>
                    <div className="schedule-hour">
                      {hour - 12 < 1 ? (hour === 12 ? hour + " noon" : hour + ":am") : (hour - 12) + "pm"}
                    </div>
                    <div className="schedule-row" ref={(el) => initDropTarget(el, 'schedule', index * 2)}>
                      <div className="schedule-task">
                        {schedule[index * 2] && (
                          <div
                            ref={(el) => initDraggable(el, 'schedule', schedule[index * 2])}
                            className={`draggable schedule-item ${schedule[index * 2].completed ? 'completed' : ''}`}
                            style={{
                              backgroundColor: schedule[index * 2].priority === "top"
                                ? '#fff3cd'
                                : '#d1ecf1'
                            }}
                            data-index={index * 2}
                          >
                            {schedule[index * 2].completed ?
                              <span className="complete-btn" title="Mark as complete" >&#10003;</span>
                              : <span className="complete-placeholder" >&nbsp;</span>} <span onClick={() => markTaskComplete(index * 2)} style={{ minWidth: '70%' }}>{schedule[index * 2].title}</span>
                            <span className="delete-btn-s" onClick={() => deleteScheduleItem(index * 2)}>x</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="schedule-row" ref={(el) => initDropTarget(el, 'schedule', index * 2 + 1)}>
                      <div className="schedule-task">
                        {schedule[index * 2 + 1] && (
                          <div
                            ref={(el) => initDraggable(el, 'schedule', schedule[index * 2 + 1])}
                            className={`draggable schedule-item ${schedule[index * 2 + 1].completed ? 'completed' : ''}`}
                            style={{
                              backgroundColor: schedule[index * 2 + 1].priority === "top"
                                ? '#fff3cd'
                                : '#d1ecf1'
                            }}
                            data-index={index * 2 + 1}
                          >
                            {
                              schedule[index * 2 + 1].completed ?
                                <span className="complete-btn" title="Mark as complete" >&#10003;</span>
                                : <span className="complete-placeholder" >&nbsp;</span>
                            }
                            <span onClick={() => markTaskComplete(index * 2 + 1)} style={{ minWidth: '70%' }}>
                              {
                                schedule[index * 2 + 1].title
                              }
                            </span>
                            <span className="delete-btn-s" onClick={() => deleteScheduleItem(index * 2 + 1)}>x</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {modalType !== null ? <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Item"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <form onSubmit={handleAddItem}>
          <h2>Add Item</h2>
          <input autoFocus={true} className="modal-text" type="text" value={newItem} onChange={(e) => setNewItem(e.target.value)} />
          <div className="modal-buttons">
            <button type="button" onClick={handleAddItem}>Add</button>
            <button type="button" onClick={closeModal}>Close</button>
          </div>
        </form>
      </Modal> : <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Copy Message"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Items Copy</h2>
        <span>Copy to current date was successful!</span>
        <div className="modal-buttons">
          <button type="button" onClick={closeModal}>Close</button>
        </div>
      </Modal>}
    </div>
  );
};

export default App;
