
import React, { useState } from 'react';
import axios from 'axios';
import tbox from "./timebox.png";

const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'https://timebox-api.naija.dev';

const Signup = ({ setView, setErrorMessage, isDarkMode }) => {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }
    try {
      await axios.post(`${SERVER_HOST}/signup`, { name, username, email, password });
      setView('login');
    } catch (error) {
      setErrorMessage('Signup failed: ' + error.response.data.message);
      console.error('Signup failed', error);
    }
  };

  if(isDarkMode){
    document.body.classList.add("dark-mode")
  }else{
    document.body.classList.remove("dark-mode")
  }

  return (
    <div className={`signup-container${isDarkMode ? ' dark-mode' : ''}`}>
      <img src={tbox} height="100" width="100" style={{ alignSelf: "center", borderRadius: "8px" }} /><hr />
      <h2 style={{ alignSelf: "center", marginTop: "-15px" }}>Signup</h2>
      <div style={{ borderTop: "2px dashed #ccc", width: "100%", paddingBottom: "15px" }}></div>
      <form onSubmit={handleSignup}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <label>Confirm Password:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <button type="submit">Signup</button>
      </form>
      <p>
        Already have an account? <button onClick={() => setView('login')}>Login</button>
      </p>
    </div>
  );
};

export default Signup;
