import React, { useState } from 'react';
import axios from 'axios';
import tbox from "./timebox.png";

const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'https://timebox-api.naija.dev';

const Login = ({ onLoginSuccess, setView, setErrorMessage, isDarkMode }) => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${SERVER_HOST}/login`, { usernameOrEmail, password });
      console.log(response.data);
      onLoginSuccess(response.data.access_token);
    } catch (error) {
      setErrorMessage('Login failed: Invalid credentials.');
      console.error('Login failed', error);
    }
  };

  if(isDarkMode){
    document.body.classList.add("dark-mode")
  }else{
    document.body.classList.remove("dark-mode")
  }

  // console.log(process.env.REACT_APP_SERVER_HOST);

  return (
    <div className={`login-container${isDarkMode ? ' dark-mode' : ''}`}>
      <img src={tbox} height="100" width="100" style={{ alignSelf: "center", borderRadius: "8px" }} /><hr />
      <h2 style={{ alignSelf: "center", marginTop: "-15px" }}>Login</h2>
      <div style={{ borderTop: "2px dashed #ccc", width: "100%", paddingBottom: "15px" }}></div>
      <form onSubmit={handleLogin}>
        <div>
          <label>Username or Email:</label>
          <input
            type="text"
            value={usernameOrEmail}
            onChange={(e) => setUsernameOrEmail(e.target.value)}
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">Login</button>
      </form>
      <p>
        Don't have an account? <button onClick={() => setView('signup')}>Signup</button>
      </p>
    </div>
  );
};

export default Login;
